import React, { useEffect, useState } from "react";
import Header from "./Header";
import SideBar from "./SideBar";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { Store } from "react-notifications-component";
import image_url from "../api/base_url";
import {
  delete_product_byId,
  get_products_admin,
  delete_Mutiple_product,
} from "../services/web/webservices";
import { Link } from "react-router-dom";

const css = `
.table-container {
  overflow-x: auto;
  overflow-y: auto; 
  max-width: 100%;
  max-height: 700px; 
}

`;

function DeliveryPackages() {
  const [showDeliverdModal, setShowDeliverdModal] = useState(false);
  const [productRowData, setSelectedRowData] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedOption, setSelectedOption] = useState("deliverd");
  const today = new Date().toISOString().split("T")[0];

  const openModal = (rowData) => {
    setSelectedRowData(rowData);
    setShowDeliverdModal(true);
  };

  const closeModal = () => {
    setShowDeliverdModal(false);
  };

  const [items, setItems] = useState([]);

  const [pageCount, setpageCount] = useState(0);

  let limit = 10;
  // let currentPage = 0;
  const [currentPage, setCurrentPage] = useState(0);
  let notificationDisplayed = false;

  const getProducts = async (currentPage, limit) => {
    try {
      const res = await get_products_admin(
        currentPage,
        limit,
        "deliverd",
        fromDate,
        toDate
      );
      if (!res || !res.data || !res.data.meta || !res.data.meta.pagination) {
        return;
      }

      setpageCount(res.data.meta.pagination.totalPageCount);
      setItems(res.data);
    } catch (error) {
      if (!notificationDisplayed) {
        notificationDisplayed = true;
        Store.addNotification({
          title: "Error!",
          message: error?.response?.data?.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
          onRemoval: () => {
            // Reset flag when notification is removed
            notificationDisplayed = false;
          },
        });
      }
    }
  };
  useEffect(() => {
    getProducts(currentPage, limit);
  }, []);

  const handleApplyFilter = async () => {
    await getProducts(0, limit);
  };

  const handlePageClick = async (data) => {
    const currentPage1 = data.selected;
    setCurrentPage(currentPage1);

    getProducts(data.selected, limit);
  };
  const handleFromDateChange = (event) => {
    const selectedDate = event.target.value;
    if (selectedDate <= toDate) {
      setFromDate(selectedDate);
    } else {
      setFromDate(selectedDate);
      setToDate(selectedDate);
    }
  };

  const handleToDateChange = (event) => {
    const selectedDate = event.target.value;
    if (selectedDate >= fromDate) {
      setToDate(selectedDate);
    } else {
      setToDate(selectedDate);
      setFromDate(selectedDate);
    }
  };

  const handleDelete = (rowData) => {
    if (window.confirm("Are You Sure To Delete Product?")) {
      delete_product_byId(rowData.id)
        .then((res) => {
          Store.addNotification({
            title: "Success",
            message: res?.data?.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          getProducts(currentPage, limit);
        })
        .catch((err) => {
          if (err) {
            Store.addNotification({
              title: "Error!",
              message: err?.response?.data?.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
        });
    }
  };

  const [isToggled, setIsToggled] = useState(false);
  const toggleClass = () => {
    setIsToggled(!isToggled);
  };

  const handleClearChange = () => {
    setFromDate("");
    setToDate("");
    window.location.reload();
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  // console.log("4444444444444444444444", selectedRows);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      // setSelectedRows(docitems?.data || []);
      setSelectedRows(items?.data.map((row) => row.id) || []);
    } else {
      setSelectedRows([]);
    }
    setSelectAll(e.target.checked);
  };

  const handleCheckboxChange = (rowData) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(rowData.id)
        ? prevSelected.filter((id) => id !== rowData.id)
        : [...prevSelected, rowData.id]
    );
  };

  const handleDeleteSelected = () => {
    const placeIds = selectedRows;
    console.log("Delete selected rows:", placeIds);
    if (window.confirm("Are You Sure To Delete?")) {
      delete_Mutiple_product({ product_id: placeIds })
        .then((res) => {
          Store.addNotification({
            title: "Success",
            message: res?.data?.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          setSelectedRows([]);
          getProducts(currentPage, limit);
        })
        .catch((err) => {
          if (err) {
            Store.addNotification({
              title: "Error!",
              message: err?.response?.data?.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
        });
    }
  };
  return (
    <>
      <div
        id="main-wrapper"
        className={isToggled ? "show menu-toggle" : "show"}
      >
        <div className="nav-header">
          <Link to="/home" className="brand-logoo">
            <img
              className="logo-abbr"
              src="images/fast-delivery.png"
              alt="logo"
            />
            <h3 className="nav-text">Boy Blue Delivery</h3>
          </Link>
          <div className="nav-control">
            <div
              className={isToggled ? "hamburger is-active" : "hamburger"}
              onClick={toggleClass}
            >
              <span className="line" />
              <span className="line" />
              <span className="line" />
            </div>
          </div>
        </div>
        <Header pageName="Delivered Packages" />

        <SideBar />
        <style>{css}</style>
        <div className="content-body default-height default-height">
          <div className="container-fluid">
            <div className="form-head d-flex mb-3 align-items-start flex-wrap justify-content-between">
              <div className="me-auto d-lg-block">
                <h3 className="text-primary font-w600" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mb-4 mt-4">
                <div className="reservation-box">
                  <div className="top">
                    <div className="static">
                      <div
                        className="input-container"
                        id="date-picker-container"
                      >
                        <label htmlFor="date-from">From date</label>
                        <input
                          type="date"
                          id="date-checkin"
                          className="date-field"
                          name="date-from"
                          value={fromDate}
                          max={today}
                          onChange={handleFromDateChange}
                        />
                      </div>
                    </div>

                    <div className="flex">
                      <div
                        className="input-container"
                        id="date-picker-container"
                      >
                        <label htmlFor="date-from">To date</label>
                        <input
                          type="date"
                          id="date-checkout"
                          className="date-field"
                          name="date-to"
                          value={toDate}
                          min={fromDate}
                          max={today}
                          onChange={handleToDateChange}
                        />
                      </div>
                      <div
                        className="button-container "
                        onClick={handleApplyFilter}
                      >
                        <span className="button ok filter">Apply</span>
                      </div>
                      <div
                        className="button-container "
                        onClick={handleClearChange}
                      >
                        <span className="button ok filter">Clear</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="upload-btn">
                  <div className="d-flex justify-content-between align-items-center">
                    <h3>Delivered Packages</h3>
                    <div className="button-container">
                      <button
                        // style={{ border: "2px solid red" }}
                        className="button ok filter"
                        onClick={handleDeleteSelected}
                        disabled={selectedRows.length === 0}
                      >
                        Delete
                      </button>
                    </div>
                    {/* <a className="text-underline" href="javascript:void(0);">
                      View All
                    </a> */}
                  </div>
                  <div className="table-container">
                    <table className="table table-responsive csv-file-section Details">
                      <thead>
                        <tr className="upper-heading">
                          <th className="u-heading">
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th className="u-heading">Distributor </th>
                          <th className="u-heading">Tracking Number</th>
                          <th className="u-heading">Salon Name</th>
                          <th className="u-heading">Customer Name</th>
                          <th className="u-heading">Status</th>
                          <th className="u-heading">Invoice Date</th>
                          <th className="u-heading">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items?.data?.map((rowData, index) => (
                          <tr key={index} className="lower-heading">
                            <td className="u-heading">
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(rowData.id)}
                                onChange={() => handleCheckboxChange(rowData)}
                              />
                            </td>
                            <td className="u-heading">
                              {rowData?.created_for != null
                                ? rowData?.created_for?.first_name
                                : "Admin"}
                            </td>
                            <td className="u-heading">
                              {rowData?.tracking_no
                                ? rowData?.tracking_no
                                : "NA"}
                            </td>
                            <td className="u-heading">
                              {rowData?.salon_name ? rowData?.salon_name : "NA"}
                            </td>
                            <td className="u-heading">
                              {rowData?.cunstomer_name
                                ? rowData?.cunstomer_name
                                : "NA"}
                            </td>
                            <td className="u-heading color-green">
                              {rowData?.status == "deliverd"
                                ? "Delivered"
                                : rowData?.status}
                            </td>

                            <td className="u-heading">
                              {moment(rowData?.invoice_date_full).format(
                                "D MMMM,YYYY"
                              )}
                            </td>
                            <td className="u-heading">
                              <i
                                className="bi bi-eye-fill"
                                title="View Detail"
                                onClick={() => openModal(rowData)}
                                style={{
                                  cursor: "pointer",
                                  marginRight: "20px",
                                  color: "#0f5a94",
                                }}
                              ></i>{" "}
                              <i
                                className="bi bi-trash-fill"
                                title="Delete"
                                onClick={() => {
                                  handleDelete(rowData);
                                }}
                                style={{
                                  cursor: "pointer",
                                  marginRight: "20px",
                                  color: "#0f5a94",
                                }}
                              ></i>
                            </td>
                          </tr>
                        ))}
                        <Modal
                          show={showDeliverdModal}
                          onHide={closeModal}
                          size="lg"
                        >
                          <Modal.Header closeButton>
                            <Modal.Title style={{ color: "#0f5a94" }}>
                              Deliverd Packages
                            </Modal.Title>
                          </Modal.Header>

                          <Modal.Body>
                            <div className="row mb-6">
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Tracking Number
                                </h6>
                                <p>
                                  {productRowData?.tracking_no
                                    ? productRowData?.tracking_no
                                    : "NA"}
                                </p>
                              </div>

                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Customer Name
                                </h6>
                                {productRowData?.customer_name
                                  ? productRowData?.customer_name
                                  : "NA"}
                              </div>

                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Salon Name
                                </h6>
                                {productRowData?.salon_name
                                  ? productRowData?.salon_name
                                  : "NA "}
                              </div>

                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Customer Phone
                                </h6>
                                {productRowData?.customer_phone
                                  ? productRowData?.customer_phone
                                  : "NA "}
                              </div>
                            </div>
                            {/* -------------------------------------------------------------- */}
                            <div className="row mb-6">
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Shipping Address 1
                                </h6>
                                {productRowData?.shipadd1
                                  ? productRowData?.shipadd1
                                  : "NA"}
                              </div>
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Shipping Address 2
                                </h6>
                                {productRowData?.shipadd2
                                  ? productRowData?.shipadd2
                                  : "NA"}
                              </div>
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Shipping City
                                </h6>
                                {productRowData?.shipcity
                                  ? productRowData?.shipcity
                                  : "NA"}
                              </div>
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Shipping State
                                </h6>
                                {productRowData?.shipstate
                                  ? productRowData?.shipstate
                                  : "NA"}
                              </div>
                            </div>

                            {/* ----------------------------------------------------------- */}
                            <div className="row mb-6">
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Shipping Zip Code
                                </h6>
                                {productRowData?.shipzip
                                  ? productRowData?.shipzip
                                  : "NA"}
                              </div>

                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Shipping Via
                                </h6>
                                {productRowData?.shipvia
                                  ? productRowData?.shipvia
                                  : "NA"}
                              </div>

                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Box No
                                </h6>
                                {productRowData?.cbox_box
                                  ? productRowData?.cbox_box
                                  : "NA"}
                              </div>

                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Num of Carton
                                </h6>
                                {productRowData?.num_of_carton
                                  ? productRowData?.num_of_carton
                                  : "NA"}
                              </div>
                            </div>
                            {/* ------------------------------------------------------- */}
                            <div className="row mb-6">
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  OrderType
                                </h6>
                                {productRowData?.order_type
                                  ? productRowData?.order_type
                                  : "NA"}
                              </div>

                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  COD
                                </h6>
                                {productRowData?.cod
                                  ? productRowData?.cod
                                  : "NA"}
                              </div>

                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Invoice Number
                                </h6>
                                <p>
                                  {productRowData?.invoice_number
                                    ? productRowData?.invoice_number
                                    : "NA"}
                                </p>
                              </div>
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Invoice Date
                                </h6>
                                <p>
                                  {productRowData?.Invoice_Date
                                    ? productRowData?.invoice_date
                                    : "NA"}
                                </p>
                              </div>
                            </div>
                            {/* ------------------------------------------------- */}
                            <div className="row mb-6">
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Invoice Year
                                </h6>
                                {productRowData?.invoice_year
                                  ? productRowData?.invoice_year
                                  : "NA"}
                              </div>
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Invoice Month
                                </h6>
                                {productRowData?.invoice_month
                                  ? productRowData?.invoice_month
                                  : "NA"}
                              </div>
                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Invoice Day
                                </h6>
                                {productRowData?.invoice_day
                                  ? productRowData?.invoice_day
                                  : "NA"}
                              </div>

                              <div className="col-md-3">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                    marginTop: "18px",
                                  }}
                                >
                                  Delivery Date
                                </h6>
                                {productRowData?.delivery_date ? (
                                  <p>
                                    {moment(
                                      productRowData.delivery_date
                                    ).format("D MMM, YYYY, h:mm:ss A")}
                                  </p>
                                ) : (
                                  <p>Delivery Date not found</p>
                                )}
                              </div>

                              <div className="col-md-12">
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Comment
                                </h6>
                                {productRowData?.comment ? (
                                  <p>{productRowData?.comment}</p>
                                ) : (
                                  <p>comment not found</p>
                                )}
                              </div>
                              <div
                                className="col-md-6"
                                style={{ marginTop: "20px" }}
                              >
                                <h6
                                  style={{
                                    color: "#0f5a94",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Image
                                </h6>
                                {/* <img
                                src={`${image_url.image_url}/${productRowData?.image}`}
                                alt="Product Image"
                              /> */}
                                {productRowData?.image ? (
                                  <img
                                    src={`${image_url.image_url}/${productRowData?.image}`}
                                    alt="Product Image"
                                  />
                                ) : (
                                  <p>Image not found</p>
                                )}
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={closeModal}
                              style={{ backgroundColor: "#0f5a94" }}
                            >
                              Close
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </tbody>
                    </table>
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeliveryPackages;
