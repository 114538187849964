import React from "react";
import { Route, Routes,Link, Navigate } from "react-router-dom";

import EditProfile from "../Component/EditProfile";
import Home from "../Component/Home";

import ChangePassword from "../Component/ChangePassword";
import AllPackage from "../Component/AllPackageDetail";
import CreateDistributor from "../Component/CreateDistributor";
import DeliveryPackages from "../Component/DeliveryPackages";
import Login from "../Component/Login";
import DistributorPackageDetail from "../Component/DistributorPackageDetail";
import ForgetPassword from "../Component/ForgetPassword";
import Managedistributor from "../Component/Managedistributor";

import Profile from "../Component/Profile";
import ProfileDistributor from "../Component/ProfileDistributor";

import {
  GuardedLogin,
  Guard_And_Navigate_To_ResetPassword,
  Guard_And_Navigate_To_Login,
  Guard_And_Navigate_To_LoginAdmin,
  Guard_And_Navigate_To_LoginDistributor,
} from "../services/authentication/GourdedRoute";
import EmailVerification from "../Component/EmailVerification";
import ResetPassword from "../Component/ResetPassword";

import EditManageDistributor from "../Component/EditManageDistributor";

function AllRoutes() {
  return (
    <>
      <Routes>

        <Route path="/" element={<GuardedLogin />}>
          <Route path="/" element={<Login />} />
        </Route>

        <Route exact path="/login" element={<GuardedLogin />}>
          <Route path="/login" element={<Login />} />
        </Route>

        <Route path="/forgotPassword" element={<ForgetPassword />} />

        <Route
          path="/verify_email/:token"
          element={<EmailVerification />}
        ></Route>

        <Route
          exact
          path="/resetPassword"
          element={<Guard_And_Navigate_To_ResetPassword />}
        >
          <Route path="/resetPassword" element={<ResetPassword />} />
        </Route>

        <Route exact path="/home" element={<Guard_And_Navigate_To_LoginAdmin />} >
          <Route path="/home" element={<Home />} />
        </Route>
        <Route exact path="/allPackage" element={<Guard_And_Navigate_To_LoginAdmin />} >
          <Route path="/allPackage" element={<AllPackage />} />
        </Route>

        
        <Route
          exact
          path="/deliveryPackage"
          element={<Guard_And_Navigate_To_LoginAdmin />}
        >
          <Route path="/deliveryPackage" element={<DeliveryPackages />} />
        </Route>
        <Route
          exact
          path="/createDistributor"
          element={<Guard_And_Navigate_To_LoginAdmin />}
        >
          <Route path="/createDistributor" element={<CreateDistributor />} />
        </Route>

        <Route
          exact
          path="/manageDistributor"
          element={<Guard_And_Navigate_To_LoginAdmin />}
        >
          <Route path="/manageDistributor" element={<Managedistributor />} />
        </Route>

        <Route exact path="/editManageDistributor/:userId"
          caseSensitive={false}
          element={<Guard_And_Navigate_To_LoginAdmin />}
        >
          <Route 
            path="/editManageDistributor/:userId"
            element={<EditManageDistributor />}
          ></Route>
        </Route>

        {/* Common Route both for admin and distributor----------------------------------------- */}

        <Route
          exact
          path="/editProfile"
          element={<Guard_And_Navigate_To_Login />}
        >
          <Route path="/editProfile" element={<EditProfile />} />
        </Route>
        <Route
          exact
          path="/changePassword"
          element={<Guard_And_Navigate_To_Login />}
        >
          <Route path="/changePassword" element={<ChangePassword />} />
        </Route>

        <Route exact path="/profile" element={<Guard_And_Navigate_To_Login />}>
          <Route path="/profile" element={<Profile />} />
        </Route>

        {/* common Route both for admin and distributor end here-------------------------------------- */}

        {/* Distributor Route starts--------------------------------------------------> */}
        <Route
          exact
          path="/distributorPackage"
          element={<Guard_And_Navigate_To_LoginDistributor />}
        >
          <Route
            exact
            path="/distributorPackage"
            element={<DistributorPackageDetail />}
          />
        </Route>

        <Route
          exact
          path="/profileDistributor"
          element={<Guard_And_Navigate_To_LoginDistributor />}
        >
          <Route
            path="/profileDistributor"
            element={<ProfileDistributor />}
          ></Route>
        </Route>

        {/* Distributor Route starts ends here--------------------------------------------------> */}

        <Route path="*" Component={NotFound} />
      </Routes>
    </>
  );
  
}
function NotFound(){
  return <Navigate to="/" />;
}

export default AllRoutes;
