import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Header from "./Header";
import SideBar from "./SideBar";
import moment from "moment";
import { Link } from "react-router-dom";
import { Store } from "react-notifications-component";

import {
  delete_product_byId,
  get_distributor,
  get_products_admin,
  updateProductById,
  update_status_byId,
  upload_csv,
  delete_Mutiple_product,
} from "../services/web/webservices";
import image_url from "../api/base_url";

const css = `
.table-container {
  overflow-x: auto; 
  overflow-y: auto; 
}
`;

function AllPackageDetail() {
  const [selectedImage, setSelectedImage] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [distributors, setDistributors] = useState([]);
  const [productRowData, setProductRowData] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [docitems, setdocItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [key, setKey] = useState(Date.now());
  const [totalRows, setTotalRows] = useState(0);
  const [localDateTime, setLocalDateTime] = useState("");
  const [utcDateTime, setUtcDateTime] = useState("");
  const today = new Date().toISOString().split("T")[0];
  const currentDatetime = new Date().toISOString().slice(0, 16);

  const handleDateTimeChange = (e) => {
    const localDateTime = e.target.value;

    let utcDateTime = "";

    if (localDateTime) {
      utcDateTime = new Date(localDateTime).toISOString(); // Convert to UTC format
    }
    setLocalDateTime(localDateTime); // Store local date and time in state
    setUtcDateTime(utcDateTime);
  };

  const limit = 10;

  const getProductsAdmin = async (currentPage = 0, limit) => {
    try {
      const res = await get_products_admin(
        currentPage,
        limit,
        selectedOption,
        fromDate,
        toDate,
        selectedOptionDistributor
      );
      if (!res || !res.data || !res.data.meta || !res.data.meta.pagination) {
        return;
      }
      setCurrentPage(currentPage);
      setpageCount(res.data.meta.pagination.totalPageCount);

      setTotalRows(res.data.meta.pagination.totalCount);

      setdocItems(res.data);
    } catch (error) {
      if (!notificationDisplayed) {
        notificationDisplayed = true;

        Store.addNotification({
          title: "Error!",
          message: error?.response?.data?.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
          onRemoval: () => {
            notificationDisplayed = false;
          },
        });
      }
      setToDate("");
      setFromDate("");
    }
  };

  useEffect(() => {
    get_distributor()
      .then((res) => {
        setDistributors(res.data.data);
      })
      .catch((err) => {});

    getProductsAdmin(currentPage, limit);
  }, []);

  const getRangeText = () => {
    const startRow = currentPage * limit + 1;
    const endRow = Math.min((currentPage + 1) * limit, totalRows);
    return `${startRow}-${endRow} out of ${totalRows}`;
  };

  const handleStatusChange = (e, item) => {
    const selectedStatus = e.target.value;
    const selectedId = item.id;
    update_status_byId(selectedStatus, selectedId)
      .then((res) => {
        Store.addNotification({
          title: "Success",
          message: "Status updated successfully",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        getProductsAdmin(currentPage, limit);
      })
      .catch((err) => {
        if (err) {
          // console.log(err);
        }
      });
  };

  const HandleUpdateProductChanges = (event) => {
    event.preventDefault();
    // --------------------------------------------------------------------------
    const formData = new FormData();
    formData.append("comment", event.target.comment.value);
    if (event?.target?.image?.files?.length > 0) {
      formData.append("image", event.target.image.files[0]);
    }
    formData.append("delivery_date", utcDateTime);

    // ---------------------------------------------------------------------------------
    updateProductById(formData, productRowData?.id)
      .then((res) => {
        Store.addNotification({
          title: "Success",
          message: res?.data?.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        getProductsAdmin(currentPage, limit);
        setSelectedImage("");
        setShowUpdateModal(false);
        setLocalDateTime("");
      })
      .catch((err) => {
        if (err) {
          Store.addNotification({
            title: "Error!",
            message: err?.response?.data?.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      });
  };
  const handlePreviewImage = (event) => {
    const Imagefile = event.target.files[0];
    const ImageFileName = event.target.files[0].name;

    setSelectedImage(ImageFileName);
  };

  const closeUpdateModal = (e) => {
    setLocalDateTime("");
    setSelectedImage(null);
    setUtcDateTime("");
    setShowUpdateModal(false);
  };

  const openModal = (rowData) => {
    setShowModal(true);
    setProductRowData(rowData);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const openUpdateModal = (productRowData) => {
    setProductRowData(productRowData);

    setShowUpdateModal(true);
  };
  let notificationDisplayed = false;

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleApplyFilter = async () => {
    // let currentPage = 0;
    setCurrentPage(0);
    getProductsAdmin(0, limit);
    setKey(Date.now());
  };

  const handleClearChange = () => {
    window.location.reload();
  };

  const handlePageClick = async (data) => {
    const currentPage1 = data.selected;
    setCurrentPage(currentPage1);
    await getProductsAdmin(currentPage1, limit);
  };

  const handleFromDateChange = (event) => {
    const selectedDate = event.target.value;
    if (selectedDate <= toDate) {
      setFromDate(selectedDate);
    } else {
      setFromDate(selectedDate);
      setToDate(selectedDate);
    }
  };

  const handleToDateChange = (event) => {
    const selectedDate = event.target.value;
    if (selectedDate >= fromDate) {
      setToDate(selectedDate);
    } else {
      setToDate(selectedDate);
      setFromDate(selectedDate);
    }
  };

  const [isToggled, setIsToggled] = useState(false);
  const toggleClass = () => {
    setIsToggled(!isToggled);
  };

  const [selectedOptionDistributor, setSelectedOptionDistributor] =
    useState("");
  const handleOptionDistributorChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOptionDistributor(selectedValue);
  };

  const handleRowDeleteChange = (rowData) => {
    if (window.confirm("Are You Sure To Delete Row?")) {
      delete_product_byId(rowData.id)
        .then((res) => {
          Store.addNotification({
            title: "Success",
            message: res?.data?.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          getProductsAdmin(currentPage, limit);
        })
        .catch((err) => {
          if (err) {
            Store.addNotification({
              title: "Error!",
              message: err?.response?.data?.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
        });
    }
  };
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  console.log("4444444444444444444444", selectedRows);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      // setSelectedRows(docitems?.data || []);
      setSelectedRows(docitems?.data.map((row) => row.id) || []);
    } else {
      setSelectedRows([]);
    }
    setSelectAll(e.target.checked);
  };

  const handleCheckboxChange = (rowData) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(rowData.id)
        ? prevSelected.filter((id) => id !== rowData.id)
        : [...prevSelected, rowData.id]
    );
  };
  const handleDeleteSelected = () => {
    const placeIds = selectedRows;
    console.log("Delete selected rows:", placeIds);
    if (window.confirm("Are You Sure To Delete?")) {
      delete_Mutiple_product({ product_id: placeIds })
        .then((res) => {
          Store.addNotification({
            title: "Success",
            message: res?.data?.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          setSelectedRows([]);
          getProductsAdmin(currentPage, limit);
        })
        .catch((err) => {
          if (err) {
            Store.addNotification({
              title: "Error!",
              message: err?.response?.data?.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
        });
    }
  };

  return (
    <>
      <div
        id="main-wrapper"
        className={isToggled ? "show menu-toggle" : "show"}
      >
        <div className="nav-header">
          <Link to="/home" className="brand-logoo">
            <img
              className="logo-abbr"
              src="images/fast-delivery.png"
              alt="logo"
            />
            <h3 className="nav-text">Boy Blue Delivery</h3>
          </Link>
          <div className="nav-control">
            <div
              className={isToggled ? "hamburger is-active" : "hamburger"}
              onClick={toggleClass}
            >
              <span className="line" />
              <span className="line" />
              <span className="line" />
            </div>
          </div>
        </div>
        <Header pageName="Delivery Details" />
        <SideBar />
        <style>{css}</style>

        <div className="content-body default-height default-height">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="row">
                  <div className="col-lg-6  mt-4 d-flex gap-3 option-btn">
                    <div className="additional-u Selection d-flex align-items-center">
                      <span className="name-input">
                        <select
                          className="select-btn input-text bg-white border-r-8"
                          value={selectedOption}
                          onChange={handleOptionChange}
                        >
                          <option value="">Status</option>
                          <option value="pending">Pending</option>
                          <option value="inprogress">In progress</option>

                          <option value="not-deliverd">Not Delivered</option>
                        </select>
                      </span>
                    </div>

                    <div className="col-lg-6 ">
                      <div className="additional-u Selection d-flex align-items-center">
                        <span className="name-input">
                          <select
                            className="select-btn input-text bg-white border-r-8"
                            value={selectedOptionDistributor}
                            onChange={handleOptionDistributorChange}
                          >
                            <option value="">Distributor</option>
                            {distributors.map((distributor, index) => (
                              <option key={index} value={distributor.id}>
                                {distributor.first_name}
                              </option>
                            ))}
                          </select>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 mb-4 mt-4">
                    <div className="reservation-box">
                      <div className="top">
                        <div className="static">
                          <div
                            className="input-container"
                            id="date-picker-container"
                          >
                            <label htmlFor="date-from">From date</label>
                            <input
                              type="date"
                              id="date-checkin"
                              className="date-field"
                              name="date-from"
                              value={fromDate}
                              max={today}
                              onChange={handleFromDateChange}
                            />
                          </div>
                        </div>
                        <div
                          className="input-container"
                          id="date-picker-container"
                        >
                          <label htmlFor="date-from">To date</label>
                          <input
                            type="date"
                            id="date-checkout"
                            className="date-field"
                            name="date-to"
                            value={toDate}
                            min={fromDate}
                            max={today}
                            onChange={handleToDateChange}
                          />
                        </div>

                        <div className="flex d-flex justify-content-center">
                          <div className="button-container">
                            <span
                              className="button ok filter"
                              onClick={handleApplyFilter}
                            >
                              {" "}
                              Apply
                            </span>
                            <span
                              className="button ok filter"
                              onClick={handleClearChange}
                            >
                              Clear
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="upload-btn  details">
                    <div className="d-flex justify-content-between align-items-center">
                      <h3>Delivery Status</h3>

                      <div className="button-container">
                        <button
                          // style={{ border: "2px solid red" }}
                          className="button ok filter"
                          onClick={handleDeleteSelected}
                          disabled={selectedRows.length === 0}
                        >
                          Delete
                        </button>
                      </div>
                    </div>

                    <div className="table-container">
                      <table className="table table-responsive csv-file-section overflow Details ">
                        <thead>
                          <tr className="upper-heading">
                            <th className="u-heading">
                              <input
                                type="checkbox"
                                checked={selectAll}
                                onChange={handleSelectAll}
                              />
                            </th>
                            <th className="u-heading">Distributor </th>
                            <th className="u-heading">Tracking Number</th>
                            <th className="u-heading">Salon Name</th>
                            <th className="u-heading">Customer Name</th>
                            {/* <th className="u-heading">Ship Add1</th>
                            <th className="u-heading">Ship Add2</th> */}

                            <th className="u-heading">Status</th>
                            {/* <th className="u-heading">Chst_customer</th> */}

                            {/* <th className="u-heading">Invoice_Month</th>
                            <th className="u-heading">Invoice_Day</th> */}
                            <th className="u-heading">Invoice Date</th>
                            <th className="u-heading">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {docitems?.data?.map((rowData, index) => (
                            <tr key={index} className="lower-heading">
                              {/* <td className="u-heading">_</td> */}
                              <td className="u-heading">
                                <input
                                  type="checkbox"
                                  checked={selectedRows.includes(rowData.id)}
                                  onChange={() => handleCheckboxChange(rowData)}
                                />
                              </td>
                              <td className="u-heading">
                                {rowData?.created_for != null
                                  ? rowData?.created_for?.first_name
                                  : "Admin"}
                              </td>
                              <td className="u-heading">
                                {rowData?.tracking_no}
                              </td>
                              <td className="u-heading">
                                {rowData?.salon_name}
                              </td>
                              <td className="u-heading">
                                {rowData?.customer_name
                                  ? rowData?.customer_name
                                  : "NA"}
                              </td>
                              {/* <td className="u-heading">
                                {rowData?.shipadd1}
                              </td>
                              <td className="u-heading">
                                {rowData?.shipadd2 ? rowData?.shipadd2: 'NA' }
                              </td> */}

                              <td className="additional-u Selection">
                                <span className="name-input">
                                  <select
                                    style={{
                                      border: "1px solid #c6c6c6",
                                      borderRadius: "8px",
                                      padding: "10px",
                                    }}
                                    className="input-tex"
                                    name="status"
                                    onChange={(e) =>
                                      handleStatusChange(e, rowData)
                                    }
                                    value={
                                      rowData?.status === "pending"
                                        ? "pending"
                                        : rowData?.status === "inprogress"
                                        ? "inprogress"
                                        : rowData?.status === "not-deliverd"
                                        ? "not-deliverd"
                                        : ""
                                    }
                                  >
                                    <option
                                      value="pending"
                                      selected={rowData?.status === "pending"}
                                    >
                                      Pending
                                    </option>
                                    <option
                                      value="inprogress"
                                      selected={
                                        rowData?.status === "inprogress"
                                      }
                                    >
                                      In progress
                                    </option>
                                    <option
                                      value="deliverd"
                                      selected={rowData?.status === "deliverd"}
                                    >
                                      Delivered{" "}
                                    </option>
                                    <option
                                      value="not-deliverd"
                                      selected={
                                        rowData?.status === "not-deliverd"
                                      }
                                    >
                                      Not Delivered
                                    </option>
                                  </select>
                                </span>
                              </td>
                              {/* <td className="u-heading">
                                {" "}
                                {rowData?.chst_customer}
                              </td> */}

                              <td className="u-heading">
                                {moment(rowData?.invoice_date_full).format(
                                  "D MMMM,YYYY"
                                )}
                              </td>
                              <td className="u-heading">
                                <i
                                  className="bi bi-eye-fill"
                                  title="View Detail"
                                  onClick={() => openModal(rowData)}
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "20px",
                                    color: "#0f5a94",
                                  }}
                                ></i>{" "}
                                <i
                                  className="bi bi-pencil-fill"
                                  title="Aditional Detail"
                                  onClick={() => openUpdateModal(rowData)}
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "20px",
                                    color: "#0f5a94",
                                  }}
                                ></i>{" "}
                                <i
                                  className="bi bi-trash-fill"
                                  title="Aditional Detail"
                                  onClick={() => handleRowDeleteChange(rowData)}
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "20px",
                                    color: "#0f5a94",
                                  }}
                                ></i>{" "}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <Modal show={showModal} onHide={closeModal} size="lg">
                        <Modal.Header closeButton>
                          <Modal.Title style={{ color: "#0f5a94" }}>
                            Package Detail
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row mb-6">
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Tracking Number
                              </h6>
                              <p>
                                {productRowData?.tracking_no
                                  ? productRowData?.tracking_no
                                  : "NA"}
                              </p>
                            </div>

                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Customer Name
                              </h6>
                              {productRowData?.customer_name
                                ? productRowData?.customer_name
                                : "NA"}
                            </div>

                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Salon Name
                              </h6>
                              {productRowData?.salon_name
                                ? productRowData?.salon_name
                                : "NA "}
                            </div>

                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Customer Phone
                              </h6>
                              {productRowData?.customer_phone
                                ? productRowData?.customer_phone
                                : "NA "}
                            </div>
                          </div>
                          {/* -------------------------------------------------------------- */}
                          <div className="row mb-6">
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Shipping Address 1
                              </h6>
                              {productRowData?.shipadd1
                                ? productRowData?.shipadd1
                                : "NA"}
                            </div>
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Shipping Address 2
                              </h6>
                              {productRowData?.shipadd2
                                ? productRowData?.shipadd2
                                : "NA"}
                            </div>
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Shipping City
                              </h6>
                              {productRowData?.shipcity
                                ? productRowData?.shipcity
                                : "NA"}
                            </div>
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Shipping State
                              </h6>
                              {productRowData?.shipstate
                                ? productRowData?.shipstate
                                : "NA"}
                            </div>
                          </div>

                          {/* ----------------------------------------------------------- */}
                          <div className="row mb-6">
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Shipping Zip Code
                              </h6>
                              {productRowData?.shipzip
                                ? productRowData?.shipzip
                                : "NA"}
                            </div>

                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Shipping Via
                              </h6>
                              {productRowData?.shipvia
                                ? productRowData?.shipvia
                                : "NA"}
                            </div>

                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Box No
                              </h6>
                              {productRowData?.cbox_box
                                ? productRowData?.cbox_box
                                : "NA"}
                            </div>

                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Num of Carton
                              </h6>
                              {productRowData?.num_of_carton
                                ? productRowData?.num_of_carton
                                : "NA"}
                            </div>
                          </div>
                          {/* ------------------------------------------------------- */}
                          <div className="row mb-6">
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                OrderType
                              </h6>
                              {productRowData?.order_type
                                ? productRowData?.order_type
                                : "NA"}
                            </div>

                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                COD
                              </h6>
                              {productRowData?.cod ? productRowData?.cod : "NA"}
                            </div>

                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Invoice Number
                              </h6>
                              <p>
                                {productRowData?.invoice_number
                                  ? productRowData?.invoice_number
                                  : "NA"}
                              </p>
                            </div>
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Invoice Date
                              </h6>
                              <p>
                                {productRowData?.Invoice_Date
                                  ? productRowData?.invoice_date
                                  : "NA"}
                              </p>
                            </div>
                          </div>
                          {/* ------------------------------------------------- */}
                          <div className="row mb-6">
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Invoice Year
                              </h6>
                              {productRowData?.invoice_year
                                ? productRowData?.invoice_year
                                : "NA"}
                            </div>
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Invoice Month
                              </h6>
                              {productRowData?.invoice_month
                                ? productRowData?.invoice_month
                                : "NA"}
                            </div>
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Invoice Day
                              </h6>
                              {productRowData?.invoice_day
                                ? productRowData?.invoice_day
                                : "NA"}
                            </div>

                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                  marginTop: "18px",
                                }}
                              >
                                Delivery Date
                              </h6>
                              {productRowData?.delivery_date ? (
                                <p>
                                  {moment(productRowData.delivery_date).format(
                                    "D MMM, YYYY, h:mm:ss A"
                                  )}
                                </p>
                              ) : (
                                <p>Delivery Date not found</p>
                              )}
                            </div>

                            <div className="col-md-12">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Comment
                              </h6>
                              {productRowData?.comment ? (
                                <p>{productRowData?.comment}</p>
                              ) : (
                                <p>comment not found</p>
                              )}
                            </div>
                            <div
                              className="col-md-6"
                              style={{ marginTop: "20px" }}
                            >
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Image
                              </h6>
                              {/* <img
                                src={`${image_url.image_url}/${productRowData?.image}`}
                                alt="Product Image"
                              /> */}
                              {productRowData?.image ? (
                                <img
                                  src={`${image_url.image_url}/${productRowData?.image}`}
                                  alt="Product Image"
                                />
                              ) : (
                                <p>Image not found</p>
                              )}
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={closeModal}
                            style={{
                              backgroundColor: "#0f5a94",
                              padding: "10px",
                              fontSize: "15px",
                              width: "150px",
                              borderRadius: "10px",
                              fontSize: "13px",
                              background: "#0f5a94",
                              color: "white",
                            }}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      {/* ------------------------------------------------------------------------------------- */}
                      <Modal
                        show={showUpdateModal}
                        onHide={closeUpdateModal}
                        size="lg"
                      >
                        <Modal.Header
                          closeButton
                          onClick={() => {
                            setShowModal(false);
                          }}
                        >
                          <Modal.Title>Additional Detail</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <form onSubmit={HandleUpdateProductChanges}>
                            <div className="row mb-6">
                              <div className="col-md-6 mb-5 ">
                                {" "}
                                {/* Use col-md-6 to display two items in a row on medium and larger screens */}
                                <h6 className=" mb-3 m-2">Comment</h6>
                                <div
                                  className="u-heading mb-3  "
                                  style={{
                                    border: "1px solid #ced4da",
                                    borderRadius: "4px",
                                  }}
                                >
                                  <textarea
                                    id="comment"
                                    name="comment"
                                    placeholder=" Write Your Comment"
                                    style={{
                                      border: "none",
                                      width: "100%",
                                      resize: "vertical",
                                      boxSizing: "border-box",
                                      padding: "6px",
                                    }}
                                  >
                                    {productRowData?.comment}
                                  </textarea>
                                </div>
                              </div>

                              <div className="col-md-6">
                                {" "}
                                {/* Use col-md-6 to display two items in a row on medium and larger screens */}
                                <h6 className=" mb-3 m-2">
                                  Delivery Date :{"  "}{" "}
                                  {productRowData?.delivery_date
                                    ? moment(
                                        productRowData.delivery_date
                                      ).format("D MMM, YYYY, h:mm:ss A")
                                    : ""}
                                </h6>
                                <div
                                  className="u-heading mb-3  "
                                  style={{
                                    border: "1px solid #ced4da",
                                    borderRadius: "4px",
                                    padding: "13px",
                                  }}
                                >
                                  <input
                                    name="dateandtime"
                                    type="datetime-local"
                                    value={localDateTime}
                                    max={currentDatetime}
                                    onChange={handleDateTimeChange}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6 mb-9 ">
                                {" "}
                                {/* Use col-md-6 to display two items in a row on medium and larger screens */}
                                <h6
                                  className=" mb-3 m-2"
                                  style={{ marginRight: "50px" }}
                                >
                                  Upload Image
                                </h6>
                                <div className="u-heading">
                                  <div className="drag-file-area upd-img">
                                    <label className="label color-black">
                                      {" "}
                                      <span className="browse-files">
                                        {" "}
                                        <input
                                          type="file"
                                          name="image"
                                          accept=".png, .jpg, .jpeg"
                                          className="default-file-input"
                                          onChange={handlePreviewImage}
                                        />
                                        <span className="browse-files-text">
                                          <i className="fa-solid fa-upload"></i>
                                          Upload Image
                                        </span>{" "}
                                      </span>
                                    </label>
                                    {selectedImage && (
                                      <div>
                                        <p>Selected Image: {selectedImage}</p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 mb-3 ">
                                {" "}
                                {productRowData?.image ? (
                                  <img
                                    src={`${image_url.image_url}/${productRowData?.image}`}
                                    alt="Product Image"
                                  />
                                ) : (
                                  <p>Image not found</p>
                                )}
                              </div>
                            </div>
                            <Modal.Footer>
                              <button
                                type="submit"
                                style={{
                                  padding: "15px",
                                  width: "150px",
                                  borderRadius: "10px",
                                  fontSize: "13px",
                                  background: "#0f5a94",
                                  color: "white",
                                }}
                              >
                                Save Changes
                              </button>
                            </Modal.Footer>
                          </form>
                        </Modal.Body>
                      </Modal>
                      {/* .................................................... */}
                      <ReactPaginate
                        key={key}
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                      <p style={{ margin: "30px" }}>{getRangeText()}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AllPackageDetail;
