import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Header from "./Header";
import SideBar from "./SideBar";
import moment from "moment";
import { Link } from "react-router-dom";
import { Store } from "react-notifications-component";

import {
  delete_product_byId,
  get_distributor,
  get_products_admin,
  updateProductById,
  update_status_byId,
  upload_csv,
  delete_Mutiple_product_track,
} from "../services/web/webservices";
import image_url from "../api/base_url";

const css = `
.table-container {
  overflow-x: auto; 
  overflow-y: auto; 
}
`;

function Home() {
  const [getUploadButton, setUploadButton] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [distributors, setDistributors] = useState([]);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [csvFile, setCsvFile] = useState(null);
  const [selectedDistributor, setSelectedDistributor] = useState(null);
  const [productRowData, setProductRowData] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [docitems, setdocItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [key, setKey] = useState(Date.now());
  const [totalRows, setTotalRows] = useState(0);
  const limit = 10;
  useEffect(() => {
    get_distributor()
      .then((res) => {
        setDistributors(res.data.data);
      })
      .catch((err) => {});

    //getProductsAdmin(currentPage, limit);
  }, []);

  const getRangeText = () => {
    const startRow = currentPage * limit + 1;
    const endRow = Math.min((currentPage + 1) * limit, totalRows);
    return `${startRow}-${endRow} out of ${totalRows}`;
  };


  const handleCsvFileChange = (event) => {
    const file = event.target.files[0];

    const fileName = event.target.files[0].name;
    setSelectedFileName(fileName);

    setCsvFile(file);
    event.target.value = "";
  };

  const handleDistributorChange = (event) => {
    const distributorId = event.target.value;

    setSelectedDistributor(distributorId);
  };
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);
  const [errorShown, setErrorShown] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setUploadButton(false);

    if (!errorShown && !csvFile) {
      setErrorShown(true);
      setUploadButtonDisabled(true);
      Store.addNotification({
        title: "Error!",
        message: "please select file",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
        onRemoval: () => {
          setErrorShown(false);
          setUploadButtonDisabled(false);
        },
      });
      setUploadButton(true);
      return false;
    }

    const formData = new FormData();
    formData.append("csvfile", csvFile);
    if (selectedDistributor != null) {
      formData.append("created_for", selectedDistributor);
    }

    upload_csv(formData, (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setUploadPercentage(percentCompleted);
    })
      .then((res) => {
        setUploadButton(true);
        Store.addNotification({
          title: "Success",
          message: res?.data?.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        setCsvFile(null);
        setSelectedFileName("");
        setSelectedDistributor("");
        //getProductsAdmin(currentPage, limit);
        console.log("res",res)
        setdocItems(res?.data?.data);
        setUploadPercentage(0);
      })
      .catch((err) => {
        setUploadButton(true);
      });
  };

  const openModal = (rowData) => {
    setShowModal(true);
    setProductRowData(rowData);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handlePageClick = async (data) => {
    const currentPage1 = data.selected;
    setCurrentPage(currentPage1);
    //await getProductsAdmin(currentPage1, limit);
  };


  const [isToggled, setIsToggled] = useState(false);
  const toggleClass = () => {
    setIsToggled(!isToggled);
  };
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      // setSelectedRows(docitems?.data || []);
      setSelectedRows(docitems?.data.map((row) => row.id) || []);
    } else {
      setSelectedRows([]);
    }
    setSelectAll(e.target.checked);
  };

  const handleCheckboxChange = (rowData) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(rowData.tracking_no)
        ? prevSelected.filter((id) => id !== rowData.tracking_no)
        : [...prevSelected, rowData.tracking_no]
    );
  };

  const handleDeleteSelected = () => {
    const placeIds = selectedRows;
    console.log("Delete selected rows:", placeIds);
    if (window.confirm("Are You Sure To Delete?")) {
      delete_Mutiple_product_track({ product_id: placeIds })
        .then((res) => {
          Store.addNotification({
            title: "Success",
            message: res?.data?.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          setSelectedRows([]);
          let dataite=docitems.filter(item => !placeIds.includes(item.tracking_no))
          setdocItems(dataite);
          //getProductsAdmin(currentPage, limit);
        })
        .catch((err) => {
          if (err) {
            Store.addNotification({
              title: "Error!",
              message: err?.response?.data?.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
        });
    }
  };

  return (
    <>
      <div
        id="main-wrapper"
        className={isToggled ? "show menu-toggle" : "show"}
      >
        <div className="nav-header">
          <Link to="/home" className="brand-logoo">
            <img
              className="logo-abbr"
              src="images/fast-delivery.png"
              alt="logo"
            />
            <h3 className="nav-text">Boy Blue Delivery</h3>
          </Link>
          <div className="nav-control">
            <div
              className={isToggled ? "hamburger is-active" : "hamburger"}
              onClick={toggleClass}
            >
              <span className="line" />
              <span className="line" />
              <span className="line" />
            </div>
          </div>
        </div>
        <Header pageName="Delivery Details" />
        <SideBar />
        <style>{css}</style>

        <div className="content-body default-height default-height">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                {/* form started here---------------------------------------------------------------------------------> */}
                <div className="table-container">
                  <form onSubmit={handleSubmit}>
                    <div className="upload-btn">
                      <div>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          <h3 style={{ marginRight: "250px" }}>
                            Upload CSV File
                          </h3>
                          <a
                            href={"images/samplecsv.csv"}
                            target="_blank"
                            style={{
                              cursor: "pointer",
                              color: "#0f5a94",
                              marginBottom: "20px",
                            }}
                          >
                            Download sample CSV?
                          </a>
                        </div>
                      </div>

                      <div className="drag-file-area">
                        <label className="label color-black">
                          {" "}
                          <span className="browse-files">
                            {" "}
                            <input
                              name="csvfile"
                              type="file"
                              className="default-file-input"
                              accept=".csv"
                              onChange={handleCsvFileChange}
                            />{" "}
                            <span className="browse-files-text">
                              <i className="fa-solid fa-upload"></i> Please
                              Select File
                            </span>{" "}
                          </span>{" "}
                        </label>
                      </div>
                      {selectedFileName && (
                        <p style={{ marginTop: "5px", color: "#4B4B4B" }}>
                          Selected file: {selectedFileName}
                        </p>
                      )}

                      <div className="distributor-Select">
                        <p>Select Distributor</p>
                        <div className="d-grid flex gap-3">
                          {distributors.map(
                            (distributor, index) =>
                              distributor.status !== "inactive" && (
                                <div key={index}>
                                  <input
                                    type="radio"
                                    id={distributor.id}
                                    name="created_for"
                                    value={distributor.id}
                                    checked={
                                      selectedDistributor === distributor.id
                                    }
                                    onChange={handleDistributorChange}
                                  />
                                  <label
                                    htmlFor={distributor.id}
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {distributor.first_name}
                                  </label>
                                  <br />
                                </div>
                              )
                          )}
                        </div>
                        {uploadPercentage > 0 && (
                          <div className="flex flex-col gap-4 mt-5">
                            <div className="w-full rounded-2xl flex flex-col gap-2 ">
                              <p className="text-gray-500">Uploading Files</p>

                              <div className="d-flex flex gap-3">
                                <div className="flex items-end gap-2 width-100">
                                  <svg
                                    className="w-10 aspect-square"
                                    viewBox="0 0 791.454 791.454"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g>
                                      <g id="Vrstva_x0020_1_4_">
                                        <path
                                          d="m202.718 0h264.814l224.164 233.873v454.622c0 56.868-46.092 102.959-102.959 102.959h-386.019c-56.868 0-102.959-46.092-102.959-102.959v-585.536c0-56.867 46.091-102.959 102.959-102.959z"
                                          fill="#0263d1"
                                        />
                                        <g fill="#fff">
                                          <path
                                            clipRule="evenodd"
                                            d="m467.212 0v231.952h224.484z"
                                            fillRule="evenodd"
                                            opacity=".302"
                                          />
                                          <path d="m195.356 564.73v-131.02h46.412c9.282 0 17.925 1.387 25.927 3.948 8.002 2.667 15.257 6.508 21.766 11.63 6.508 5.121 11.63 11.95 15.364 20.485s5.655 18.351 5.655 29.447-1.921 20.912-5.655 29.447-8.856 15.364-15.364 20.485-13.764 8.962-21.766 11.63c-8.002 2.561-16.644 3.948-25.927 3.948zm32.755-28.487h9.709c5.228 0 10.136-.64 14.51-1.814 4.481-1.28 8.535-3.307 12.376-5.975s6.828-6.508 8.962-11.523c2.241-4.908 3.307-10.883 3.307-17.711s-1.067-12.803-3.307-17.818c-2.134-4.908-5.121-8.749-8.962-11.416-3.841-2.774-7.895-4.694-12.376-5.975-4.374-1.174-9.282-1.814-14.51-1.814h-9.709zm161.855 29.981c-19.738 0-36.062-6.402-48.972-19.098-12.91-12.697-19.312-28.701-19.312-47.905 0-19.205 6.402-35.209 19.312-47.905 12.91-12.697 29.234-19.098 48.972-19.098 19.418 0 35.529 6.402 48.439 19.098 12.803 12.697 19.205 28.701 19.205 47.905s-6.402 35.209-19.205 47.905c-12.91 12.696-29.021 19.098-48.439 19.098zm-25.18-39.37c6.508 7.255 14.83 10.883 24.966 10.883s18.351-3.628 24.86-10.883c6.508-7.362 9.709-16.538 9.709-27.634s-3.201-20.272-9.709-27.634c-6.508-7.255-14.724-10.883-24.86-10.883s-18.458 3.628-24.966 10.883c-6.508 7.362-9.816 16.538-9.816 27.634s3.308 20.272 9.816 27.634zm170.71 39.37c-19.098 0-34.996-5.975-47.585-17.711-12.697-11.843-18.991-28.274-18.991-49.293 0-20.912 6.402-37.343 19.205-49.186 12.91-11.843 28.594-17.818 47.372-17.818 16.964 0 30.834 4.161 41.824 12.59 10.883 8.322 17.178 19.418 18.778 33.288l-33.075 6.722c-1.387-7.255-4.695-13.123-9.816-17.498s-11.096-6.615-17.925-6.615c-9.389 0-17.178 3.308-23.473 10.029-6.295 6.828-9.496 16.217-9.496 28.487s3.201 21.659 9.389 28.381c6.295 6.828 14.084 10.136 23.579 10.136 6.828 0 12.697-1.92 17.498-5.761s7.789-8.962 9.069-15.364l33.822 7.682c-3.094 13.23-9.923 23.473-20.592 30.834-10.562 7.363-23.792 11.097-39.583 11.097z" />
                                        </g>
                                      </g>
                                    </g>
                                  </svg>

                                  <div className="w-full space-y-1">
                                    <div className="flex justify-between">
                                      <span>
                                        CSV file {uploadPercentage}% uploaded
                                      </span>
                                    </div>
                                    <div className="h-2 w-full bg-gray-300 rounded-md">
                                      <div
                                        className="h-2 w-10/12 bg-blue-500 rounded-md"
                                        style={{
                                          width: `${uploadPercentage}%`,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      {getUploadButton === true ? (
                        <button type="submit" disabled={uploadButtonDisabled}>
                          <div className="button-container">
                            <span className="button ok filter">Submit</span>
                          </div>
                        </button>
                      ) : (
                        <button type="submit" disabled={true}>
                          <div className="button-container">
                            <span className="button ok filter">
                              uploading...
                            </span>
                          </div>
                        </button>
                      )}
                    </div>
                  </form>
                </div>

                {/* form ended here------------------------------------------------------------------> */}

                <div className="row">
                  
                </div>
                {docitems?.length >0 &&
                <div className="col-lg-12 col-md-12">
                  <div className="upload-btn  details">
                    <div className="d-flex justify-content-between align-items-center">
                      <h3>Uploaded Data</h3>
                      <div className="button-container">
                        <button
                          // style={{ border: "2px solid red" }}
                          className="button ok filter"
                          onClick={handleDeleteSelected}
                          disabled={selectedRows.length === 0}
                        >
                          Delete
                        </button>
                      </div>
                    </div>

                    <div className="table-container">
                      <table className="table table-responsive csv-file-section overflow Details ">
                        <thead>
                          <tr className="upper-heading">
                            <th className="u-heading">
                              <input
                                type="checkbox"
                                checked={selectAll}
                                onChange={handleSelectAll}
                              />
                            </th>

                            <th className="u-heading">Distributor </th>
                            <th className="u-heading">Tracking Number</th>
                            <th className="u-heading">Salon Name</th>
                            <th className="u-heading">Customer Name</th>
                            <th className="u-heading">Status</th>
                            <th className="u-heading">Invoice Date</th>
                            <th className="u-heading">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {docitems?.map((rowData, index) => (
                            <tr key={index} className="lower-heading">
                              <td className="u-heading">
                                <input
                                  type="checkbox"
                                  checked={selectedRows.includes(rowData.tracking_no)}
                                  onChange={() => handleCheckboxChange(rowData)}
                                />
                              </td>
                              {/* <td className="u-heading">_</td> */}
                              <td className="u-heading">
                                {rowData?.created_for != null
                                  ? rowData?.distributor_name
                                  : "Admin"}
                              </td>
                              <td className="u-heading">
                                {rowData?.tracking_no}
                              </td>
                              <td className="u-heading">
                                {rowData?.salon_name}
                              </td>
                              <td className="u-heading">
                                {rowData?.customer_name
                                  ? rowData?.customer_name
                                  : "NA"}
                              </td>
                             

                              <td className="additional-u Selection">
                                <span className="name-input"> Pending</span>
                              </td>
                              {/* <td className="u-heading">
                                {" "}
                                {rowData?.chst_customer}
                              </td> */}

                              <td className="u-heading">
                                {moment(rowData?.invoice_date_full).format(
                                  "D MMMM,YYYY"
                                )}
                              </td>
                              <td className="u-heading">
                                <i
                                  className="bi bi-eye-fill"
                                  title="View Detail"
                                  onClick={() => openModal(rowData)}
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "20px",
                                    color: "#0f5a94",
                                  }}
                                ></i>{" "}
                                
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <Modal show={showModal} onHide={closeModal} size="lg">
                        <Modal.Header closeButton>
                          <Modal.Title style={{ color: "#0f5a94" }}>
                            Package Detail
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row mb-6">
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Tracking Number
                              </h6>
                              <p>
                                {productRowData?.tracking_no
                                  ? productRowData?.tracking_no
                                  : "NA"}
                              </p>
                            </div>

                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Customer Name
                              </h6>
                              {productRowData?.customer_name
                                ? productRowData?.customer_name
                                : "NA"}
                            </div>

                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Salon Name
                              </h6>
                              {productRowData?.salon_name
                                ? productRowData?.salon_name
                                : "NA "}
                            </div>

                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Customer Phone
                              </h6>
                              {productRowData?.customer_phone
                                ? productRowData?.customer_phone
                                : "NA "}
                            </div>
                          </div>
                          {/* -------------------------------------------------------------- */}
                          <div className="row mb-6">
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Shipping Address 1
                              </h6>
                              {productRowData?.shipadd1
                                ? productRowData?.shipadd1
                                : "NA"}
                            </div>
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Shipping Address 2
                              </h6>
                              {productRowData?.shipadd2
                                ? productRowData?.shipadd2
                                : "NA"}
                            </div>
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Shipping City
                              </h6>
                              {productRowData?.shipcity
                                ? productRowData?.shipcity
                                : "NA"}
                            </div>
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Shipping State
                              </h6>
                              {productRowData?.shipstate
                                ? productRowData?.shipstate
                                : "NA"}
                            </div>
                          </div>

                          {/* ----------------------------------------------------------- */}
                          <div className="row mb-6">
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Shipping Zip Code
                              </h6>
                              {productRowData?.shipzip
                                ? productRowData?.shipzip
                                : "NA"}
                            </div>

                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Shipping Via
                              </h6>
                              {productRowData?.shipvia
                                ? productRowData?.shipvia
                                : "NA"}
                            </div>

                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Box No
                              </h6>
                              {productRowData?.cbox_box
                                ? productRowData?.cbox_box
                                : "NA"}
                            </div>

                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Num of Carton
                              </h6>
                              {productRowData?.num_of_carton
                                ? productRowData?.num_of_carton
                                : "NA"}
                            </div>
                          </div>
                          {/* ------------------------------------------------------- */}
                          <div className="row mb-6">
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                OrderType
                              </h6>
                              {productRowData?.order_type
                                ? productRowData?.order_type
                                : "NA"}
                            </div>

                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                COD
                              </h6>
                              {productRowData?.cod ? productRowData?.cod : "NA"}
                            </div>

                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Invoice Number
                              </h6>
                              <p>
                                {productRowData?.invoice_number
                                  ? productRowData?.invoice_number
                                  : "NA"}
                              </p>
                            </div>
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Invoice Date
                              </h6>
                              <p>
                                {productRowData?.Invoice_Date
                                  ? productRowData?.invoice_date
                                  : "NA"}
                              </p>
                            </div>
                          </div>
                          {/* ------------------------------------------------- */}
                          <div className="row mb-6">
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Invoice Year
                              </h6>
                              {productRowData?.invoice_year
                                ? productRowData?.invoice_year
                                : "NA"}
                            </div>
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Invoice Month
                              </h6>
                              {productRowData?.invoice_month
                                ? productRowData?.invoice_month
                                : "NA"}
                            </div>
                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Invoice Day
                              </h6>
                              {productRowData?.invoice_day
                                ? productRowData?.invoice_day
                                : "NA"}
                            </div>

                            <div className="col-md-3">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                  marginTop: "18px",
                                }}
                              >
                                Delivery Date
                              </h6>
                              {productRowData?.delivery_date ? (
                                <p>
                                  {moment(productRowData.delivery_date).format(
                                    "D MMM, YYYY, h:mm:ss A"
                                  )}
                                </p>
                              ) : (
                                <p>Delivery Date not found</p>
                              )}
                            </div>

                            <div className="col-md-12">
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Comment
                              </h6>
                              {productRowData?.comment ? (
                                <p>{productRowData?.comment}</p>
                              ) : (
                                <p>comment not found</p>
                              )}
                            </div>
                            <div
                              className="col-md-6"
                              style={{ marginTop: "20px" }}
                            >
                              <h6
                                style={{
                                  color: "#0f5a94",
                                  marginBottom: "5px",
                                }}
                              >
                                Image
                              </h6>
                              {/* <img
                                src={`${image_url.image_url}/${productRowData?.image}`}
                                alt="Product Image"
                              /> */}
                              {productRowData?.image ? (
                                <img
                                  src={`${image_url.image_url}/${productRowData?.image}`}
                                  alt="Product Image"
                                />
                              ) : (
                                <p>Image not found</p>
                              )}
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={closeModal}
                            style={{
                              backgroundColor: "#0f5a94",
                              padding: "10px",
                              fontSize: "15px",
                              width: "150px",
                              borderRadius: "10px",
                              fontSize: "13px",
                              background: "#0f5a94",
                              color: "white",
                            }}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      {/* ------------------------------------------------------------------------------------- */}
                     
                      {/* .................................................... */}
                      <ReactPaginate
                        key={key}
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                      <p style={{ margin: "30px" }}>{getRangeText()}</p>
                    </div>
                  </div>
                </div>

            }


              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Home;
